import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints, mode } from '@chakra-ui/theme-tools';

// Global style overrides
// import styles from './styles';

// Component style overrides
import Button from './components/button';
import Heading from './components/Heading';
import Input from './components/Input';

// const fonts = { mono: `'DM Sans', monospace` };

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});

// const config = {
//   initialColorMode: 'light',
//   useSystemColorMode: false,
// };

const theme = extendTheme({
  // config,
  // styles,
  colors: {
    black: '#16161D',
    brand: {
      100: '#f7fafc',
      900: '#1a202c',
    },
  },
  fonts: {
    heading: 'Blinker',
    body: 'DM Sans',
  },
  breakpoints,
  components: {
    Button,
    Heading,
    Input,
    // Other components go here
  },
  icons: {
    logo: {
      path: (
        <svg width="3000" height="3163" viewBox="0 0 3000 3163" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="3000" height="3162.95" fill="none" />
          <path
            d="M1470.89 1448.81L2170 2488.19H820V706.392H2170L1470.89 1448.81ZM1408.21 1515.37L909.196 2045.3V2393.46H1998.84L1408.21 1515.37Z"
            fill="currentColor"
          />
        </svg>
      ),
      viewBox: '0 0 3000 3163',
    },
  },
});

export default theme;
