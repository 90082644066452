import React from 'react';
import { Flex, Box, Text, Icon, Image, useColorMode } from '@chakra-ui/react';
import { FaTwitter, FaLinkedin, FaDiscord } from 'react-icons/fa';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Link as LinkChakra,
} from '@chakra-ui/react';
import { RiInstagramFill } from 'react-icons/ri';
import Link from 'next/link';
import { isMobile } from 'react-device-detect';
import { Container } from 'components/Container';

const MenuItem = ({ children, isLast, to = '/', deviceType, ...rest }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 2, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
      fontSize={['16px', '16px', '14px']}
      fontWeight="semibold"
    >
      <Link href={to}>{children}</Link>
    </Text>
  );
};

const PopOVerText = () => {
  return (
    <Popover placement="top-start" trigger="hover">
      <PopoverTrigger>
        <Flex alignItems="center" cursor="pointer">
          <Text mb="5px">💎 NFTs on Bitcoin</Text>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody fontSize="12px" color="gray.600">
          All NFTs are stored on IPFS (
          <LinkChakra href="https://ipfs.io" isExternal color="blue.400">
            https://ipfs.io
          </LinkChakra>
          ) and minted on the Stacks Blockchain (
          <LinkChakra href="https://www.stacks.co/" isExternal color="blue.400">
            https://www.stacks.co/
          </LinkChakra>
          ) using the SIP-009 Standard (
          <LinkChakra
            href="https://github.com/stacksgov/sips/blob/main/sips/sip-009/sip-009-nft-standard.md"
            isExternal
            color="blue.400"
          >
            https://github.com/stacksgov/sips/blob/main/sips/sip-009/sip-009-nft-standard.md
          </LinkChakra>
          ). Stacks enables smart contracts on the Bitcoin blockchain ({' '}
          <LinkChakra href="https://bitcoin.org/bitcoin.pdf" isExternal color="blue.400">
            https://bitcoin.org/bitcoin.pdf
          </LinkChakra>
          ). A public link to the blockchain record is provided with every NFT transaction.
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const Footer = ({}) => {
  const [_isMobile, setMobile] = React.useState();
  React.useEffect(() => {
    setMobile(isMobile);
  }, [setMobile]);
  const year = new Date().getFullYear();
  const { colorMode } = useColorMode();
  return (
    <Box bg={colorMode === 'dark' ? null : '#F9F9F9'} id="footer" p={_isMobile ? '60px 20px' : '64px 0px 19px 0px'}>
      {!_isMobile && colorMode === 'light' && (
        <Image
          alt="imgFooter"
          width="100vw"
          mb="20px"
          height="250px"
          bg="#FAFBFD"
          objectFit="cover"
          src="/images/lines-bg-bottom@2x.png"
        />
      )}
      <Container>
        {!_isMobile ? (
          <Flex direction="column" m="0 auto" w="100%" id="footer_content">
            <Flex flex="1" justifyContent="space-between" id="footer_menu">
              <Flex alignItems="center">
                <Flex id="logo_footer" w="80px">
                  <Image objectFit="contain" src="/images/logo_icon.png" alt="logoFooter" width={50} height={50} />
                </Flex>
                <Flex id="social_media" w="283px">
                  <a href="https://www.instagram.com/heylayer/" target="_blank" rel="noreferrer">
                    <Icon color="#626262" as={RiInstagramFill} w="26px" h="26px" mr="40px" cursor="pointer" />
                  </a>
                  <a href="https://twitter.com/heylayer" target="_blank" rel="noreferrer">
                    <Icon color="#626262" as={FaTwitter} w="24px" h="24px" mr="30px" cursor="pointer" />
                  </a>

                  <a href="https://www.linkedin.com/company/layer-technologies" target="_blank" rel="noreferrer">
                    <Icon color="#626262" as={FaLinkedin} w="24px" h="24px" mr="30px" cursor="pointer" />
                  </a>
                  <a href="https://discord.gg/7PFtJdbbFw" target="_blank" rel="noreferrer">
                    <Icon color="#626262" as={FaDiscord} w="24px" h="24px" mr="30px" cursor="pointer" />
                  </a>
                </Flex>
              </Flex>
              <Flex id="menu_footer" flexDirection="column" fontSize="14px" fontWeight="500" alignItems="flex-end">
                <PopOVerText />
                <Text>❤️ to all warriors in battle against COVID-19</Text>
              </Flex>
            </Flex>
            <Flex mt="20px" id="copyright">
              <Text fontSize="12px" fontWeight="500" color="#3a3a3a" pr="10px">
                © {year} Layer Technologies INC.
              </Text>
              <Text fontSize="12px" fontWeight="500" color="blue.500">
                <Link href="/terms-of-service">
                  <a>Terms of Service</a>
                </Link>
              </Text>
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex flex="1" flexDirection="column" alignItems="center">
              <Flex id="logo_footer" mb="20px">
                <Image src="/images/logo_icon.png" objectFit="contain" alt="logoFooter" width={20} height={20} />
              </Flex>
              <Flex id="social_media" mt="30px" mb="50px">
                <a href="https://www.instagram.com/heylayer/" target="_blank" rel="noreferrer">
                  <Icon as={RiInstagramFill} w="36px" h="36px" mr="40px" cursor="pointer" />
                </a>
                <a href="https://twitter.com/heylayer" target="_blank" rel="noreferrer">
                  <Icon as={FaTwitter} w="24px" h="34px" mr="40px" cursor="pointer" />
                </a>

                <a href="https://www.linkedin.com/company/layer-technologies" target="_blank" rel="noreferrer">
                  <Icon as={FaLinkedin} w="34px" h="34px" mr="40px" cursor="pointer" />
                </a>
                <a href="https://discord.gg/7PFtJdbbFw" target="_blank" rel="noreferrer">
                  <Icon as={FaDiscord} w="34px" h="34px" cursor="pointer" />
                </a>
              </Flex>
              <Flex id="menu_footer" flexDirection="column">
                <PopOVerText />
                <Text>❤️ to all warriors in the battle against COVID-19</Text>
              </Flex>
            </Flex>
            <Flex mt="40px" justifyContent="center" flexDirection="column" alignItems="center">
              <Text fontSize="16px" fontWeight="500" color="blue.500">
                <Link href="/terms-of-service">
                  <a>Terms of Service</a>
                </Link>
              </Text>
              <Text fontSize="16px" fontWeight="500">
                © {year} Layer Technologies INC.
              </Text>
            </Flex>
          </>
        )}
      </Container>
    </Box>
  );
};
