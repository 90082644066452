/* eslint-disable react/display-name */
import { FormErrorMessage, FormLabel, FormControl, Input, useColorMode } from '@chakra-ui/react';

const CreateInput =
  () =>
  ({
    field,
    form,
    label,
    submitCount,
    height,
    hasBottom,
    showError = true,
    fontSize,
    fontWeight,
    onChange,
    isInvalid,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    let handleInputChange = (e) => {
      // console.log('e', e.target.value);
      let inputValue = e.target.value;
      form.setFieldValue(field.name, inputValue);
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colorMode } = useColorMode();
    return (
      <FormControl
        isInvalid={isInvalid ? isInvalid : submittedError || touchedError ? true : false}
        mb={submittedError || touchedError ? '4px' : hasBottom ? hasBottom : '23px'}
      >
        {label && (
          <FormLabel mb="3px" fontSize={fontSize ? fontSize : '13px'} fontWeight={fontWeight || '500'}>
            {label}
          </FormLabel>
        )}
        <Input
          autoComplete="new-password"
          fontSize="14px"
          borderRadius={5}
          borderColor={colorMode === 'dark' ? '#333' : '#ddd'}
          height={height ? height : '42px'}
          {...field}
          {...props}
          onChange={onChange ? onChange : handleInputChange}
          _placeholder={{ color: '#7f7f7f', fontSize: '14px' }}
          _invalid={{ borderColor: '#e53e3e' }}
          _focus={{ borderColor: '#000' }}
          _disabled={{ color: 'gray.500' }}
          color={colorMode === 'dark' ? 'gray.300' : '#141416'}
          fontWeight="500"
        />
        {showError && (
          <FormErrorMessage fontSize="10px" mt={1}>
            {submittedError || touchedError ? hasError : false}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  };

export default CreateInput;
