/* eslint-disable react/display-name */
import React from 'react';
import styled from '@emotion/styled';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.mx';
import { FormErrorMessage, FormLabel, FormControl, Flex, useColorMode } from '@chakra-ui/react';

export const InputStyle = styled.div`
  input {
    font-size: 13px;
    padding-left: 1rem;
    padding-right: 1rem;
    height: ${(props) => props.height || '35px'};
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid;
    width: 100%;
    background: ${(props) => props.bg};
    border-color: ${(props) => (props.hasError ? '#e53e3e' : '#ddd')};
  }
  input[type='text']:focus {
    outline: none;
    border-color: #000;
  }
  input::placeholder {
    color: #7f7f7f;
  }
`;

const CreateInputCleave =
  () =>
  ({
    field,
    form,
    label,
    submitCount,
    height,
    options,
    isMulti = false,
    onSelect,
    isClearable = true,
    hasBottom,
    showError,
    fontSize,
    isInvalid,
    placeholder,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colorMode } = useColorMode();

    let handleInputChange = (e) => {
      // console.log('e', e.target.value);
      let inputValue = e.target.value;
      form.setFieldValue(field.name, inputValue);
    };

    return (
      <FormControl
        isInvalid={isInvalid ? isInvalid : submittedError || touchedError ? true : false}
        // mb={submittedError || touchedError ? (showError ? '0px' : '15px') : hasBottom ? hasBottom : '23px'}
        mb={submittedError || touchedError ? (hasBottom ? hasBottom : '4px') : hasBottom ? hasBottom : '23px'}
      >
        {label && (
          <FormLabel mb="5px" fontSize={fontSize ? fontSize : '13px'} fontWeight="500">
            {label}
          </FormLabel>
        )}
        <InputStyle
          hasError={hasError || isInvalid}
          height={height}
          colorMode={colorMode}
          bg={colorMode === 'dark' ? '#394353' : '#fff'}
        >
          <Flex pos="revert">
            <Cleave
              value={field.value}
              options={options}
              type="text"
              onChange={handleInputChange}
              placeholder={placeholder}
            />
          </Flex>
        </InputStyle>

        {showError && (
          <FormErrorMessage fontSize="10px" mt={1}>
            {submittedError || touchedError ? hasError : false}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  };

export default CreateInputCleave;
