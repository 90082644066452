import { toast } from 'react-toastify';
const createNotification = (type, message, options) => {
  // const defaultOptions = {
  //   autoClose: 6000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  // };
  const defaultOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  // toast[type](message, defaultOptions);
  toast(message, defaultOptions);
};
export default createNotification;
