import React from 'react';
import Cookie from 'js-cookie';
import { isLocale } from './currency.utils';

const CurrencyContext = React.createContext({});

export const CurrencyProvider = ({ children }) => {
  const [currency, setLocale] = React.useState('stx');
  const [showFilter, setShowFilter] = React.useState(false);

  const changeCurrency = (newLocale) => {
    setLocale(newLocale);
    document.documentElement.lang = newLocale;
    Cookie.set('currency', newLocale);
  };

  const changeFilter = (option) => {
    setShowFilter(option);
    document.documentElement.showFilter = option;
    Cookie.set('showFilter', option);
  };

  React.useEffect(() => {
    const localSetting = Cookie.get('currency');
    const localFilter = Cookie.get('showFilter');

    if (localSetting && isLocale(localSetting)) {
      document.documentElement.lang = localSetting;
      setLocale(localSetting);
    }

    if (localFilter) {
      console.log('default', localFilter);
      document.documentElement.showFilter = localFilter;
      setShowFilter(localFilter === 'false' ? false : true);
    }
  }, [currency]);

  return (
    <CurrencyContext.Provider value={{ currency, changeCurrency, showFilter, changeFilter }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => React.useContext(CurrencyContext);
