// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: process.env.REACT_APP_API_URL,
};

// const hoverBg = '#deebff';
const hoverBg = '#DEEEFA';
// const hoverBgDark = '#1A202C';
const hoverBgDark = '#2D3748';

const defaultImage = 'https://res.cloudinary.com/dzfajfr7g/image/upload/v1618371134/no-image_vqta2u.png';

const breakpoints = {
  480: {
    width: 480,
    slidesPerView: 2,
  },
  640: {
    width: 640,
    slidesPerView: 2,
  },
  768: {
    width: 768,
    slidesPerView: 3,
  },
  1024: {
    width: 1024,
    slidesPerView: 4,
  },
  1200: {
    width: 1200,
    slidesPerView: 5,
  },
  1400: {
    width: 1400,
    slidesPerView: 5,
  },
};

const boxColor = { light: '#fff', dark: '#23252F' };
const bgColor = { light: 'white', dark: '#1c1c1c' };
const bgColorB = { light: '#f7f7f7', dark: '#141414' };
const bgColorC = { light: '#f7f7f7', dark: '#1c1c1c' };
const borderColor = { light: null, dark: '#333' };
const bgTag = { light: '#f8f8f8', dark: 'gray.700' };
const colorText = { light: null, dark: 'gray.300' };

const PAGE_SIZE = 15;

export {
  PAGE_SIZE,
  hoverBg,
  hoverBgDark,
  boxColor,
  bgColor,
  bgColorB,
  bgColorC,
  borderColor,
  bgTag,
  defaultImage,
  breakpoints,
  colorText,
};
