import React from 'react';
import Router from 'next/router';
import { Flex } from '@chakra-ui/react';
import Image from 'next/image';

const Logo = ({ onClick }) => {
  function onLogoClick() {
    Router.push('/');
    if (onClick) {
      onClick();
    }
  }

  return (
    <Flex align="center" id="logo" pl={0} cursor="pointer">
      <a href="https://heylayer.com/">
        <Image src="/images/logo_icon.png" alt="logo" width={60} height={60} quality="100%" />
      </a>

    </Flex>
  );
};

export default Logo;
