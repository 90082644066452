/* eslint-disable react/display-name */
import React from 'react';
import styled from '@emotion/styled';
import Cleave from 'cleave.js/react';
import { FormErrorMessage, FormLabel, FormControl, Icon, Flex, useColorMode } from '@chakra-ui/react';
import { FaCcAmex, FaCcDinersClub, FaCcDiscover, FaCcJcb, FaCcMastercard, FaCcVisa } from 'react-icons/fa';

export const InputCardStyle = styled.div`
  input {
    font-size: 14px;
    padding-left: 1rem;
    padding-right: 1rem;
    height: ${(props) => props.height || '35px'};
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid;
    width: 100%;
    background: ${(props) => props.bg};
    border-color: ${(props) => (props.hasError ? '#e53e3e' : '#ddd')};
  }
  input[type='text']:focus {
    outline: none;
    border-color: #000;
  }
  input::placeholder {
    color: #7f7f7f;
  }
`;

const CreateInputCard =
  () =>
  ({
    field,
    form,
    label,
    submitCount,
    height,
    options,
    isMulti = false,
    onSelect,
    isClearable = true,
    hasBottom,
    fontSize,
    placeholder,
    type,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colorMode } = useColorMode();

    let handleInputChange = (e) => {
      // console.log('e', e.target.value);
      let inputValue = e.target.value;
      form.setFieldValue(field.name, inputValue);
    };
    const iconStyle = { pos: 'absolute', right: 3, top: 27, fontSize: 25, color: '#555' };
    return (
      <FormControl
        isInvalid={submittedError || touchedError ? true : false}
        mb={submittedError || touchedError ? '4px' : hasBottom ? hasBottom : '23px'}
      >
        {label && (
          <FormLabel mb="3px" fontSize={fontSize ? fontSize : '13px'} fontWeight="500">
            {label}
          </FormLabel>
        )}
        <InputCardStyle
          hasError={hasError}
          height={height}
          colorMode={colorMode}
          bg={colorMode === 'dark' ? '#394353' : '#fff'}
        >
          <Flex pos="revert">
            <Cleave options={options} type="text" onChange={handleInputChange} placeholder={placeholder} />
            {type === 'visa' && <Icon as={FaCcVisa} {...iconStyle} />}
            {type === 'mastercard' && <Icon as={FaCcMastercard} {...iconStyle} />}
            {type === 'discover' && <Icon as={FaCcDiscover} {...iconStyle} />}
            {type === 'amex' && <Icon as={FaCcAmex} {...iconStyle} />}
            {type === 'jcb' && <Icon as={FaCcJcb} {...iconStyle} />}
            {type === 'diners' && <Icon as={FaCcDinersClub} {...iconStyle} />}
          </Flex>
        </InputCardStyle>

        <FormErrorMessage fontSize="10px" mt={1}>
          {submittedError || touchedError ? hasError : false}
        </FormErrorMessage>
      </FormControl>
    );
  };

export default CreateInputCard;
