import React from 'react';
import { Flex, Heading, Button, Text, Image } from '@chakra-ui/react';

const SuccessLink = ({ onSubmit, email }) => {
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="60px">
        <Image src="/images/icon-magic-hat.png" w="92px" alt="" />
        <Heading fontSize="20px" mt="25px" px="30px" textAlign="center">
          Magic link sent
        </Heading>
        <Text mt="15px" fontSize="14px" textAlign="center" fontWeight="500">
          We just sent an email to you at {email}
        </Text>
        <Text mb="40px" fontSize="14px" textAlign="center" fontWeight="500">
          It contains a link that will sign you in super quick.
        </Text>
      </Flex>
      <Flex px="5px" justifyContent="center">
        <Button colorScheme="messenger" w="100%" onClick={onSubmit} fontSize="12px">
          Done
        </Button>
      </Flex>
    </Flex>
  );
};
export default SuccessLink;
