import React, { useReducer } from 'react';
import { AuthContext } from './auth.context';
const isBrowser = typeof window !== 'undefined';
const INITIAL_STATE = {
  isAuthenticated: isBrowser && !!localStorage.getItem('access_token'),
  currentForm: 'signIn',
  user: isBrowser ? JSON.parse(localStorage.getItem('user')) : {},
  showModal: false,
  upload: false,
  path: null,
};

function reducer(state, action) {
  // console.log(state, 'auth');

  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        showModal: true,
        nft: action.payload || null,
        path: action.path || null,
      };

    case 'SHOW_MODAL_UPLOAD':
      return {
        ...state,
        upload: true,
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        showModal: false,
      };
    case 'HIDE_MODAL_UPLOAD':
      return {
        ...state,
        upload: false,
      };
    case 'SIGNIN':
      return {
        ...state,
        currentForm: 'signIn',
      };
    case 'SIGNIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload || {},
      };
    case 'SIGN_OUT':
      return {
        ...state,
        isAuthenticated: false,
      };
    case 'SIGNUP':
      return {
        ...state,
        currentForm: 'signUp',
      };
    case 'FORGOTPASS':
      return {
        ...state,
        currentForm: 'forgotPass',
      };
    default:
      return state;
  }
}

export const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);
  return <AuthContext.Provider value={{ authState, authDispatch }}>{children}</AuthContext.Provider>;
};
