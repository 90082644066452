import { createIcon } from '@chakra-ui/icons';
// using `path`
export const UpDownIcon = createIcon({
  displayName: 'UpDownIcon',
  viewBox: '0 0 200 200',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />,
});

export const ArrowRight = createIcon({
  displayName: 'ArrowRight',
  viewBox: '0 0 268.832 268.832',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      fill="currentColor"
      d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
    c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
    c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"
    />
  ),
});

export const CloseIcon = ({ color }) => (
  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M32 0H0v32h32z" />
      <g fill={color || '#000'} fillRule="nonzero">
        <path d="M25.428 6.572a1.327 1.327 0 0 0-1.876 0l-16.98 16.98a1.327 1.327 0 0 0 1.876 1.876l16.98-16.98a1.327 1.327 0 0 0 0-1.876z" />
        <path d="M6.572 6.572a1.327 1.327 0 0 1 1.876 0l16.98 16.98a1.327 1.327 0 0 1-1.876 1.876L6.572 8.448a1.327 1.327 0 0 1 0-1.876z" />
      </g>
    </g>
  </svg>
);

export const MenuIcon = ({ color }) => (
  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M32 0H0v32h32z" />
      <g fill={color || '#000'} fillRule="nonzero">
        <path d="M29.333 7.993c0-.733-.594-1.326-1.326-1.326h-10.68a1.327 1.327 0 0 0 0 2.653h10.68c.732 0 1.326-.594 1.326-1.327zM29.333 16c0-.733-.594-1.327-1.326-1.327H3.993a1.327 1.327 0 1 0 0 2.654h24.014c.732 0 1.326-.594 1.326-1.327zM28.007 22.68a1.327 1.327 0 0 1 0 2.653H11.993a1.327 1.327 0 1 1 0-2.653h16.014z" />
      </g>
    </g>
  </svg>
);

export const ArrowRightS = (props) => {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      className={'arrow arrow--right' + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
			c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
			l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
			c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
      />
    </svg>
  );
};

export const ArrowLeftS = (props) => {
  const disabeld = props.disabled ? ' arrow--disabled-left' : '';
  return (
    <svg
      onClick={props.onClick}
      className={'arrow arrow--left' + disabeld}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        d="M492,236H68.442l70.164-69.824c7.829-7.792,7.859-20.455,0.067-28.284c-7.792-7.83-20.456-7.859-28.285-0.068
			l-104.504,104c-0.007,0.006-0.012,0.013-0.018,0.019c-7.809,7.792-7.834,20.496-0.002,28.314c0.007,0.006,0.012,0.013,0.018,0.019
			l104.504,104c7.828,7.79,20.492,7.763,28.285-0.068c7.792-7.829,7.762-20.492-0.067-28.284L68.442,276H492
			c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"
      />
    </svg>
  );
};

export const CollectionIcon = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M16.744 1.667a.556.556 0 0 0-.555-.556H3.967a.556.556 0 0 0-.556.556v.555h13.333v-.555zM17.844 3.889a.556.556 0 0 0-.555-.556H2.844a.556.556 0 0 0-.555.556v.555h15.555V3.89zM17.844 5.556H2.156c-.577 0-1.045.467-1.045 1.044v10.133c0 .577.468 1.045 1.045 1.045h15.688c.577 0 1.045-.468 1.045-1.045V6.6c0-.577-.468-1.044-1.045-1.044z"
        fill={color || '#141416'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const FilterIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.6 6a1.2 1.2 0 0 1 1.2-1.2h14.4a1.2 1.2 0 0 1 0 2.4H4.8A1.2 1.2 0 0 1 3.6 6zm2 6a1.2 1.2 0 0 1 1.2-1.2h10.4a1.2 1.2 0 1 1 0 2.4H6.8A1.2 1.2 0 0 1 5.6 12zm3 6a1.2 1.2 0 0 1 1.2-1.2H14a1.2 1.2 0 1 1 0 2.4H9.8A1.2 1.2 0 0 1 8.6 18z"
      fill={color || "#141414"}
    />
  </svg>
);
