import { Flex, useColorMode } from '@chakra-ui/react';

export const Container = (props) => {
  const { colorMode } = useColorMode();

  const bgColor = { light: 'white', dark: 'gray.900' };

  const color = { light: 'black', dark: 'white' };
  return (
    <Flex
      id="container"
      direction="column"
      maxW={{ xl: '1440px' }}
      m="0 auto"
      p={[0, 0, 0, '0 10px', '0 54px']}
      // bg={bgColor[colorMode]}
      color={color[colorMode]}
      {...props}
    />
  );
};
