import cryptoRandomString from 'crypto-random-string';
import { createContext, useReducer, useContext } from 'react';
import { setCookie, deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';

const AuthContext = createContext();
const AuthDispatchContext = createContext();

const GENERATE_STATE = 'GENERATE_STATE';
const RESET_PROCESS = 'RESET_PROCESS';

const initialState = {
  cryptoState: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case GENERATE_STATE:
      return { ...state, cryptoState: action.payload.randomString };
    case RESET_PROCESS:
      return { ...state, ...initialState };
    default:
      return { ...state };
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();
  const goToOauth = () => {
    const randomString = cryptoRandomString({ length: 10, type: 'url-safe' });
    const client_id = process.env.NEXT_PUBLIC_CLIENT_ID;
    const redirect_uri = process.env.NEXT_PUBLIC_CALLBACK_URL;
    const BASE_URL = process.env.NEXT_PUBLIC_API_URL_AUTH;
    const scope =
      'nft:create nft:update nft:transfer nft:burn listings:auctions:create listings:auctions:update listings:auctions:close listings:buy-it-now:create listings:buy-it-now:update listings:buy-it-now:close';
    const oauthURL = `${BASE_URL}/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${randomString}`;

    dispatch({ type: GENERATE_STATE, payload: { randomString } });
    setCookie('random-key', randomString);
    // window.open(oauthURL, '_blank');
    router.push(oauthURL);
    // console.log('oauthURL', oauthURL);
  };

  const finishOauth = () => {
    dispatch({ type: RESET_PROCESS, payload: false });
    deleteCookie('random-key');
    deleteCookie('temp-badge');
  };

  return (
    <AuthDispatchContext.Provider
      value={{
        goToOauth,
        finishOauth,
      }}
    >
      <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
