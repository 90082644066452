import React from 'react';
import { isEmpty } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Input,
  InputGroup,
  Spinner,
  useColorMode,
  Avatar,
  Image,
  Button,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { bgColor, borderColor } from 'config/data';

export const Search = ({ value = '', onSearch, onRedirect, loading = false, collections = [] }) => {
  const artists = collections?.users || [];
  const non_fungible_tokens = collections?.non_fungible_tokens || [];
  const non_fungible_token_collections = collections?.non_fungible_token_collections || [];
  const tags = collections?.tags || [];

  const { colorMode } = useColorMode();
  return (
    <Box
      id="search"
      ml={{ base: 2, md: '10px', lg: '10px', xl: '15px' }}
      pr={{ sm: '10px', md: 0 }}
      w={{ base: '100%', md: '370px', lg: '440px', xl: '320px', '2xl': '360px' }}
      pos="relative"
    >
      <InputGroup>
        <Input
          borderColor={borderColor[colorMode]}
          borderWidth={2}
          borderRightWidth={0}
          placeholder="Search NFT Marketplace"
          _placeholder={{ color: '#7f7f7f', fontSize: '14px' }}
          _invalid={{ borderColor: '#e53e3e' }}
          _focus={{ borderColor: '#000' }}
          _disabled={{ color: 'gray.500' }}
          color={colorMode === 'dark' ? 'gray.300' : '#141416'}
          fontSize="14px"
          fontWeight="400"
          onChange={(e) => onSearch(e.target.value)}
          borderRadius="8px 0 0 8px"
        />
        <IconButton
          borderColor={borderColor[colorMode]}
          borderWidth={2}
          borderRadius="0 8px 8px 0"
          aria-label="Search"
          icon={<FiSearch fontSize="18px" />}
        />
      </InputGroup>
      {value && (
        <Flex
          id="search_content"
          minH="120px"
          w="100%"
          pos="absolute"
          bg={bgColor[colorMode]}
          borderColor={borderColor[colorMode]}
          mt="5px"
          zIndex="9999"
          boxShadow="0 4px 10px 1px rgba(96, 109, 123, 0.1)"
          borderRadius={5}
          borderWidth={1}
        >
          {loading ? (
            <Flex alignItems="center" justifyContent="center" flex="1">
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
            </Flex>
          ) : (
            <Flex id="content" w="100%">
              {isEmpty(artists) &&
              isEmpty(non_fungible_tokens) &&
              isEmpty(non_fungible_token_collections) &&
              isEmpty(tags) ? (
                <Flex alignItems="center" justifyContent="center" id="no_search" w="100%">
                  <Text color="gray.500">No results. Try a new search.</Text>
                </Flex>
              ) : (
                <Box w="100%">
                  <Scrollbars style={{ height: collections?.length <= 3 ? '300px' : '448px' }}>
                    {non_fungible_tokens.map((i, idx) => (
                      <Flex
                        key={idx}
                        borderBottomWidth={1}
                        borderColor={borderColor[colorMode]}
                        p="5px 15px"
                        alignItems="center"
                      >
                        <Image
                          borderRadius={5}
                          w="33px"
                          h="33px"
                          src={i.file?.thumbnail_http_url || '/images/no_image.jpg'}
                          mr="15px"
                          alt=""
                        />
                        <Box onClick={() => onRedirect(`/nft/${i.id}`)}>
                          <Text fontWeight="500" fontSize="15px" lineHeight="20px" cursor="pointer">
                            {i.name}
                          </Text>
                        </Box>
                      </Flex>
                    ))}

                    {!isEmpty(non_fungible_token_collections) && (
                      <Box borderBottomWidth={1} borderColor={borderColor[colorMode]} w="100%" p="8px 15px">
                        <Text fontWeight="semibold" fontSize="12px">
                          ARTISTS
                        </Text>
                      </Box>
                    )}
                    {artists.map((a, idx) => (
                      <Flex
                        key={idx}
                        borderBottomWidth={1}
                        borderColor={borderColor[colorMode]}
                        p="8px 15px"
                        alignItems="center"
                      >
                        <Avatar w="28px" h="28px" bg="#BC3153" color="#fff" name={a.username} mr="15px" size="sm" />{' '}
                        <Box onClick={() => onRedirect(`/${a.username}`)}>
                          <Text fontWeight="500" fontSize="15px" cursor="pointer">
                            {a.username}
                          </Text>
                        </Box>
                        {a?.is_verified && <Image ml="4px" w="15px" h="15px" src="/images/check_profile.svg" alt="" />}
                      </Flex>
                    ))}

                    {!isEmpty(non_fungible_token_collections) && (
                      <Box borderBottomWidth={1} borderColor={borderColor[colorMode]} w="100%" p="8px 15px">
                        <Text fontWeight="semibold" fontSize="12px">
                          COLLECTIONS
                        </Text>
                      </Box>
                    )}

                    {non_fungible_token_collections.map((i, idx) => (
                      <Flex
                        key={idx}
                        borderBottomWidth={1}
                        borderColor={borderColor[colorMode]}
                        p="5px 15px"
                        alignItems="center"
                      >
                        <Image
                          borderRadius={5}
                          w="33px"
                          h="33px"
                          src={i.logo_file?.thumbnail_http_url || '/images/no_image.jpg'}
                          mr="15px"
                          alt=""
                        />
                        <Box onClick={() => onRedirect(`/collection/${i.id}`)}>
                          <Text fontWeight="500" fontSize="15px" lineHeight="20px" cursor="pointer">
                            {i.name}
                          </Text>
                          {/* <Text fontSize="13px" color="gray.400">
                            16 Items
                          </Text> */}
                        </Box>
                      </Flex>
                    ))}
                  </Scrollbars>
                  {!isEmpty(tags) && (
                    <Box borderBottomWidth={1} borderColor={borderColor[colorMode]} w="100%" p="8px 15px">
                      <Text fontWeight="semibold" fontSize="12px">
                        RELATED TAGS
                      </Text>
                    </Box>
                  )}
                  <Flex p="10px 15px" mb="8px" flexWrap="wrap">
                    {tags.map((i, idx) => (
                      <Button
                        key={idx}
                        bg={colorMode === 'dark' ? '#404040' : '#f8fcff'}
                        color={colorMode === 'dark' ? '' : null}
                        borderColor={colorMode === 'dark' ? '#333' : '#d4e5f6'}
                        borderWidth={1}
                        fontSize="14px"
                        fontWeight={500}
                        mr="10px"
                        mb="10px"
                        onClick={() => onRedirect(`/marketplace?tags=${i.id}`)}
                      >
                        {i.name}
                      </Button>
                    ))}
                  </Flex>
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      )}
    </Box>
  );
};
export default Search;
