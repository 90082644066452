import { AppConfig, UserSession, showConnect, openSTXTransfer } from '@stacks/connect';
import {
  uintCV,
  callReadOnlyFunction,
  cvToValue,
  PostConditionMode,
  trueCV,
  falseCV,
  stringAsciiCV,
  standardPrincipalCV,
  noneCV,
  FungibleConditionCode,
  makeStandardSTXPostCondition,
  NonFungibleConditionCode,
  createAssetInfo,
  makeStandardNonFungiblePostCondition,
} from '@stacks/transactions';
import { StacksTestnet, StacksMainnet } from '@stacks/network';
import { openContractCall } from '@stacks/connect';
import { decryptECIES } from '@stacks/encryption';

const BigNum = require('bn.js');
const CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT;
const CONTRACT_NAME = process.env.NEXT_PUBLIC_CONTRACT_NAME;

const CONTRACT_ADDRESS_AUTH = process.env.NEXT_PUBLIC_CONTRACT;
const CONTRACT_NAME_AUTH = process.env.NEXT_PUBLIC_CONTRACT_NAME_V2;

const network = process.env.NEXT_PUBLIC_ENV === 'production' ? new StacksMainnet() : new StacksTestnet();

const appConfig = new AppConfig(['store_write', 'publish_data']);
export const userSession = new UserSession({ appConfig });
// const storage = new Storage({ userSession });

export function authenticate(func) {
  showConnect({
    appDetails: {
      name: 'HeyLayer NFT Gallery',
      icon: 'https://i.imgur.com/HODRARC.png',
    },
    onFinish: () => {
      func();
      // Save or otherwise utilize userData post-authentication
    },
    userSession: userSession,
  });
}

export function getAllTokenData(tokenID, contractName) {
  const options = {
    contractAddress: CONTRACT_ADDRESS,
    contractName: contractName || CONTRACT_NAME,
    functionName: 'get-all-token-data',
    functionArgs: [uintCV(tokenID)],
    network,
    senderAddress: CONTRACT_ADDRESS,
  };
  // let callFuncPromise =
  return callReadOnlyFunction(options)
    .then((CVresult) => {
      // console.log('cvresult', CVresult);
      const tokenData = {
        owner: cvToValue(CVresult.value.data['token-owner']),
        metaData: cvToValue(CVresult.value.data['token-data']),
      };
      return tokenData;
    })
    .catch((err) => err);
  // .catch((err) => err);
  // return callFuncPromise.
}

export async function purchaseNFT(tokenID, resolve) {
  const userS =
    process.env.NEXT_PUBLIC_ENV === 'production'
      ? userSession.loadUserData().profile.stxAddress.mainnet
      : userSession.loadUserData().profile.stxAddress.testnet;
  const postConditionAddress = userS;
  const postConditionCode = FungibleConditionCode.LessEqual;
  const tokenData = await getAllTokenData(tokenID);
  const tokenPrice = new BigNum(tokenData.metaData['price'].value);

  const standardSTXPostCondition = makeStandardSTXPostCondition(postConditionAddress, postConditionCode, tokenPrice);

  const postConditionCodeNFT = NonFungibleConditionCode.DoesNotOwn;
  const assetName = 'Layer-NFT';
  const nonFungibleAssetInfo = createAssetInfo(CONTRACT_ADDRESS, CONTRACT_NAME, assetName);

  const standardNonFungiblePostCondition = makeStandardNonFungiblePostCondition(
    tokenData.owner,
    postConditionCodeNFT,
    nonFungibleAssetInfo,
    uintCV(tokenID)
  );

  const options = {
    contractAddress: CONTRACT_ADDRESS,
    contractName: CONTRACT_NAME,
    functionName: 'purchase',
    postConditionMode: PostConditionMode.Allow,
    network,
    functionArgs: [uintCV(tokenID)],
    postConditions: [standardSTXPostCondition, standardNonFungiblePostCondition],
    postConditionMode: PostConditionMode.Deny,
    appDetails: {
      name: 'Layer Marketplace',
      icon: window.location.origin + '/my-app-logo.svg',
    },
    onFinish: (data) => {
      console.log('Transaction', data);
      console.log('Stacks Transaction:', data.stacksTransaction);
      console.log('Transaction ID:', data.txId);
      console.log('Raw transaction:', data.txRaw);
      resolve(data.txId);
    },
  };

  await openContractCall(options);
}

export async function purchaseNFTLayer(amount, memo, resolve) {
  const options = {
    recipient: process.env.NEXT_PUBLIC_CONTRACT,
    amount,
    memo: memo || 'Purchase',
    network, // for mainnet, `new StacksMainnet()`
    appDetails: {
      name: 'Layer Marketplace',
      icon: window.location.origin + '/my-app-logo.svg',
    },
    onFinish: (data) => {
      console.log('Transaction', data);
      console.log('Stacks Transaction:', data.stacksTransaction);
      console.log('Transaction ID:', data.txId);
      console.log('Raw transaction:', data.txRaw);
      resolve(data.txId);
    },
  };

  await openSTXTransfer(options);
}

export async function setTokenPriceData(tokenID, price, forSale, resolve) {
  const active = forSale ? trueCV() : falseCV();
  const options = {
    contractAddress: CONTRACT_ADDRESS,
    contractName: CONTRACT_NAME,
    functionName: 'set-token-price-data',
    network,
    functionArgs: [uintCV(tokenID), uintCV(price), active],
    appDetails: {
      name: 'Layer Marketplace',
      icon: window.location.origin + '/my-app-logo.svg',
    },
    onFinish: (data) => {
      console.log('Stacks Transaction:', data.stacksTransaction);
      console.log('Transaction ID:', data.txId);
      console.log('Raw transaction:', data.txRaw);
      resolve(data.txId);
    },
  };
  await openContractCall(options);
}

export async function transferToken(tokenID, owner, recipient, resolve, contractName) {
  const postConditionCodeNFT = NonFungibleConditionCode.DoesNotOwn;
  const assetName = contractName === 'wave-riders-club-by-surfing-com' ? 'surfing' : 'Layer-NFT';
  const nonFungibleAssetInfo = createAssetInfo(CONTRACT_ADDRESS, contractName || CONTRACT_NAME, assetName);

  const standardNonFungiblePostCondition = makeStandardNonFungiblePostCondition(
    owner,
    postConditionCodeNFT,
    nonFungibleAssetInfo,
    uintCV(tokenID)
  );

  const options = {
    contractAddress: CONTRACT_ADDRESS,
    contractName,
    functionName: 'transfer',
    network,
    functionArgs: [uintCV(tokenID), standardPrincipalCV(owner), standardPrincipalCV(recipient)],
    appDetails: {
      name: 'Layer Marketplace',
      icon: window.location.origin + '/my-app-logo.svg',
    },
    postConditionMode: PostConditionMode.Deny,
    postConditions: [standardNonFungiblePostCondition],
    onFinish: (data) => {
      console.log('Stacks Transaction:', data.stacksTransaction);
      console.log('Transaction ID:', data.txId);
      console.log('Raw transaction:', data.txRaw);
      resolve(data.txId);
    },
  };
  await openContractCall(options);
}

export async function mintNFT(nftData, resolve) {
  const { tokenID, name, price } = nftData;
  // console.log(setTxId);
  // console.log("data in mint", nftData);
  const options = {
    contractAddress: CONTRACT_ADDRESS,
    contractName: CONTRACT_NAME,
    functionName: 'mint-nft',
    network,
    functionArgs: [uintCV(tokenID), uintCV(price), trueCV(), stringAsciiCV(name), noneCV()],
    appDetails: {
      name: 'Layer Marketplace',
      icon: window.location.origin + '/my-app-logo.svg',
    },
    onFinish: (data) => {
      resolve(data.txId);
      // setTxId(data.txId);
      // setTxId(data.txId);
      console.log('Stacks Transaction:', data.stacksTransaction);
      console.log('Transaction ID:', data.txId);
      console.log('Raw transaction:', data.txRaw);
      const url = `https://explorer.stacks.co/txid/${data.txId}?chain=testnet`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 2000);
    },
  };
  // console.log("options", options);
  await openContractCall(options);
}

export async function validateAuth(privateKey, cipherObj, resolve) {
  const deciphered = await decryptECIES(privateKey, cipherObj);

  const options = {
    contractAddress: CONTRACT_ADDRESS_AUTH,
    contractName: CONTRACT_NAME_AUTH,
    functionName: 'validate-auth',
    network,
    functionArgs: [stringAsciiCV(deciphered)],
    sponsored: true,
    appDetails: {
      name: 'Layer Marketplace',
      icon: 'https://i.imgur.com/HODRARC.png',
    },
    onFinish: (data) => {
      // console.log(data);
      // const stacksTransaction = data.stacksTransaction;
      // console.log('stacksTransaction', stacksTransaction);

      // const json = JSON.stringify(stacksTransaction, (key, value) =>
      //   typeof value === 'bigint' ? value.toString() + 'n' : value
      // );

      // const transaction = new Buffer(json).toString('hex');
      // const signature = await signECDSA(privateKey, transaction);

      // console.log('json', json);
      // console.log('deciphered', deciphered);
      // console.log('transaction', transaction);
      // console.log('signature', signature);

      const stacksTransaction = data.stacksTransaction;
      resolve(stacksTransaction);

      // console.log('Stacks Transaction:', data.stacksTransaction);
      // console.log('Transaction ID:', data.txId);
      // console.log('Raw transaction:', data.txRaw);
      // const url = `https://explorer.stacks.co/txid/${data.txId}?chain=testnet`;
    },
  };
  // console.log("options", options);
  await openContractCall(options);
}

// async function executeCall() {
//   const options = {
//     contractAddress: "ST2124A54ZRRE8TCK86RYSSSNNX9QNQHFNA8SQH15",
//     contractName: "breezy-chocolate-eel",
//     functionName: "mint-nft",
//     postConditionMode: PostConditionMode.Allow,
//     network: network,
//     postConditions: [],
//     // functionArgs: [],
//     functionArgs: [
//       uintCV(6004408231326),
//       uintCV(2000000),
//       trueCV(),
//       stringAsciiCV("pluto77"),
//       listCV([standardPrincipalCV("ST1VD9XDE0X5R23PD89R9TFZ7BY94Z0ZP7454BSRD")]),
//       listCV([uintCV(5000)]),
//     ],

//     appDetails: {
//       name: "My App",
//       icon: window.location.origin + "/my-app-logo.svg",
//     },
//     onFinish: (data) => {
//       console.log("Stacks Transaction:", data.stacksTransaction);
//       console.log("Transaction ID:", data.txId);
//       console.log("Raw transaction:", data.txRaw);
//     },
//   };

//   await openContractCall(options);
// }

// export const getStacksData = (FILE_ID, USER_ID) => {
//   const fileURL = `file/${FILE_ID}`;
//   const config = {
//     // app: "https://layer-tech.netlify.app",
//     // app: "https://localhost:3000",
//     // app: window.location.origin,
//     // app: "https://60c89b02d73be2f567ea357e--locker-app.netlify.app",
//     // app: "https://60c89b02d73be2f567ea357e--locker-app.netlify.app/",
//     // app: "https://60c89b02d73be2f567ea357e--locker-app.netlify.app/dashboard/default",
//     // app: "https://locker-app.netlify.app/",
//     // app: "https://hub.blockstack.org/1PXjTiZew8ynrGyzY9QZyoZrp6uHLxHMjz/",
//     // app: "https://layer-v2-staging.netlify.app",
//     // app: "https://layer.netlify.app/",
//     // app: "https://layer-tech.netlify.app",
//     // app: "http://localhost:3000",
//     app: "https://layer-tech.netlify.app/",
//     username: USER_ID,
//     zoneFileLookupURL: "https://core.blockstack.org/v1/names",
//     verify: false,
//     decrypt: false,
//   };
//   console.log("config", config, fileURL);

//   return storage
//     .getFile(fileURL, config)
//     .then((rawData) => JSON.parse(rawData || "[]"))
//     .then((data) => console.log("stacks data", data))
//     .catch((error) => {
//       console.error("problem getting data from stacks\n", error);
//     });
// };
