import React from 'react';
import Router from 'next/router';
import { Flex, Image, useColorMode } from '@chakra-ui/react';

const Logo = ({ onClick }) => {
  function onLogoClick() {
    Router.push('/');
    if (onClick) {
      onClick();
    }
  }

  const { colorMode } = useColorMode();

  return (
    <Flex
      align="center"
      id="logo"
      pl={0}
      pr={{ base: 0, sm: '10px', md: '10px' }}
      onClick={onLogoClick}
      cursor="pointer"
    >
      {colorMode === 'dark' ? (
        <a href="https://heylayer.com/">
          <Image src="/images/Logo-DarkMode.svg" w="180px" alt="logo" />
        </a>

      ) : (
        <a href="https://heylayer.com/">
          <Image src="/images/logo.png" w="180px" alt="logo" />
        </a>
      )}
    </Flex>
  );
};

export default Logo;
