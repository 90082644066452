import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { deviceType } from 'react-device-detect';
import { useRouter } from 'next/router';
import { authenticate, userSession, validateAuth } from 'utils/stacks';
import { Modal, ModalOverlay, ModalContent, ModalBody, useColorMode, Icon } from '@chakra-ui/react';
import { Flex, Heading, Text, Button, Divider, Image, Link } from '@chakra-ui/react';
import { decryptECIES, signECDSA } from '@stacks/encryption';
import notification from 'components/Notifications';
import { Field, Formik } from 'formik';
import { Input } from 'components/InputForm';
import SuccessLink from './success';
import useUser from 'lib/useUser';
import { API } from 'config/api';
import { bgColor, borderColor, colorText } from 'config/data';
import { RiCloseCircleLine } from 'react-icons/ri';

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email address').required('Enter your email'),
});

const LoginModal = ({ isOpen, onClose, nft, path }) => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [device, setDevice] = useState('');
  const { colorMode } = useColorMode();
  // const [isAuthenticated, setIsAuthenticated] = useState(userSession.isUserSignedIn());
  const pathname = router.asPath;
  // console.log('pathname', pathname);
  const { mutateUser } = useUser();

  useEffect(() => {
    setDevice(deviceType);
    setSuccess(false);
  }, [setDevice]);

  const onSubmit = (data) => {
    if (typeof window !== 'undefined') {
      setLoading(true);
      const auth_url = process.env.NEXT_PUBLIC_AUTH_URL;
      API.post('/auth/login/magic-link', { email: data.email, auth_url })
        .then(async (res) => {
          const currentPath = nft ? `/nft/${nft}` : path ? path : pathname;
          await axios.post('/api/current', { pathname: currentPath });
          setLoading(false);
          setSuccess(true);
          setEmail(data.email);
          window.gtag('event', 'sign_up', {
            method: 'email',
            email: data.email,
          });
        })
        .catch(() => {
          setSuccess(false);
          setLoading(false);
        });
    }
  };

  const onLoginWallet = () => {
    setCargando(true);
    authenticate(async () => {
      let userData = userSession.loadUserData();
      const appsMeta = userData.profile.appsMeta;
      const address =
        process.env.NEXT_PUBLIC_ENV === 'production'
          ? userData.profile.stxAddress.mainnet
          : userData.profile.stxAddress.testnet;

      const private_key = userData.appPrivateKey;
      const appUrl = process.env.NEXT_PUBLIC_ENV === 'dev' ? 'http://localhost:3000' : process.env.NEXT_PUBLIC_URL;
      const public_key = appsMeta[appUrl].publicKey;
      API.post('/auth/login/wallet/initiate', { address, public_key }).then(async (res) => {
        // console.log('res', res.data);
        const challenge_token = res.data.challenge_token;
        const method = res.data.method;
        console.log(method);
        if (method === 'sign_challenge_token') {
          const deciphered = await decryptECIES(private_key, challenge_token);
          const signature = await signECDSA(private_key, deciphered);
          const dataComplete = {
            challenge_token: deciphered,
            signature: signature.signature,
            public_key,
            address,
            method,
          };
          API.post('/auth/login/wallet/complete', dataComplete)
            .then(async (resAuth) => {
              try {
                mutateUser(await axios.post('/api/login_wallet', resAuth.data.data)).then(() => {
                  setCargando(false);
                  onClose();
                });
              } catch (error) {
                setCargando(false);
                notification('error', error.response.data.message);
              }
            })
            .catch((errAuth) => {
              setCargando(false);
              notification('error', errAuth.response.data.message);
            });
        } else {
          const p = new Promise((resolve) => validateAuth(private_key, challenge_token, resolve));
          p.then(async (stacksTransaction) => {
            const transaction = stacksTransaction.serialize().toString('hex');
            const deciphered = await decryptECIES(private_key, challenge_token);
            const signature = await signECDSA(private_key, transaction);
            const dataComplete = {
              public_key,
              address,
              signature: signature.signature,
              transaction,
              challenge_token: deciphered,
              method,
            };
            API.post('/auth/login/wallet/complete', dataComplete)
              .then(async (resAuth) => {
                try {
                  mutateUser(await axios.post('/api/login_wallet', resAuth.data.data)).then(() => {
                    setCargando(false);
                    onClose();
                  });
                } catch (error) {
                  setCargando(false);
                  notification('error', error.response.data.message);
                }
              })
              .catch((errAuth) => {
                setCargando(false);
                notification('error', errAuth.response.data.message);
              });
          }).catch((e) => setCargando(false));
        }
      });
    });
  };

  const onButton = () => {
    setSuccess(false);
    onClose();
  };

  const onCloseModal = () => {
    setLoading(false);
    setCargando(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        w={{ base: '90%', md: '400px' }}
        mt={['30px', '30px', '131px']}
        bg={bgColor[colorMode]}
        color={colorText[colorMode]}
      >
        <ModalBody pb="30px" mt="15px" px={['15px', '15px', '25px']}>
          <Flex justifyContent="flex-end" alignItems="center">
            <Text fontSize={['16px', '16px', '12px']} mr="10px" fontWeight="bold">
              CLOSE
            </Text>
            <Flex cursor="pointer" onClick={onCloseModal}>
              <Icon as={RiCloseCircleLine} fontSize={['31px', '31px', '23px']} />
            </Flex>
          </Flex>
          <Heading fontSize={['36px', '36px', '24px']} mb="10px">
            SIGN IN
          </Heading>
          <Divider borderWidth={1} borderColor={borderColor[colorMode]} mb="5px" />
          {success ? (
            <SuccessLink onSubmit={onButton} onClose={onClose} email={email} />
          ) : (
            <Fragment>
              <Heading fontSize="20px" py="15px">
                Enter your email to receive a sign in link
              </Heading>
              <Text mb="15px" fontSize="14px">
                If this is your first time, it will create a new account.
              </Text>
              <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    onSubmit(values);
                    actions.setSubmitting(false);
                  }, 100);
                }}
              >
                {({ handleSubmit, submitCount }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      component={Input}
                      name="email"
                      placeholder="Your email"
                      submitCount={submitCount}
                      height="48px"
                    />
                    <Text fontSize="12px" mt="18px">
                      By Signing in you agree to our{' '}
                      <Link
                        href="https://docs.google.com/document/d/e/2PACX-1vTkT-XtxEu9GYjo3-XpNDAvrdMRgIX19Xp5nGu5HKGq5zCttKq_RoBGQzYQpobHjQ/pub"
                        target="_blank"
                        color="blue.500"
                      >
                        Terms & Conditions.{' '}
                      </Link>
                    </Text>

                    <Button
                      isLoading={loading}
                      fontSize="12px"
                      mt={['19px', '19px', '30px']}
                      w="100%"
                      colorScheme="messenger"
                      h={['48px', '48px', '42px']}
                      type="submit"
                    >
                      Sign in with Email
                    </Button>
                  </form>
                )}
              </Formik>
              {/* {device !== 'mobile' && (
                <Fragment>
                  <Text textAlign="center" fontSize="12px" py="15px">
                    Or
                  </Text>
                  <Button
                    isLoading={cargando}
                    leftIcon={<Image src="/images/hiro-logo.svg" width="25px" height="18px" alt="" mr="5px" />}
                    variant="black"
                    w="100%"
                    mb="10px"
                    h={['48px', '48px', '42px']}
                    fontSize="12px"
                    onClick={onLoginWallet}
                  >
                    Use Stacks Hiro Wallet
                  </Button>
                </Fragment>
              )} */}
            </Fragment>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
