import React, { Fragment, useEffect, useContext, useMemo } from 'react';
import debounce from 'lodash/debounce';
import Link from 'next/link';
// import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import { Box, Flex, Text, Avatar, Button, Image, useColorMode } from '@chakra-ui/react';
import Logo from 'layouts/Logo';
import LogoMobile from 'layouts/LogoMobile';
import { deviceType } from 'react-device-detect';
import LoginModal from 'layouts/LoginModal';
import { Container } from 'components/Container';
import { userSession } from 'utils/stacks';
import fetchJson from 'lib/fetchJson';
import useUser from 'lib/useUser';
import { CloseIcon, MenuIcon } from 'theme/icons';
import { AuthContext } from 'contexts/auth/auth.context';
import { useAuthDispatch } from 'contexts/0Auth';
import axios from 'axios';
import Search from './search';
import { API } from 'config/api';

const AuthMenu = dynamic(() => import('./auth-menu'), { ssr: false });

export const Header = () => {
  const { user, mutateUser } = useUser();
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState('');
  const [collections, setCollections] = React.useState([]);
  const [value, setValue] = React.useState('');
  const toggleMenu = () => setShow(!show);
  const { colorMode } = useColorMode();
  const bgColor = { light: 'white', dark: 'gray.900' };
  const color = { light: 'black', dark: 'white' };
  const [device, setDevice] = React.useState('');

  const { goToOauth } = useAuthDispatch();

  const router = useRouter();

  const {
    authState: { showModal, nft },
    authDispatch,
  } = useContext(AuthContext);

  useEffect(() => {
    setDevice(deviceType);
  }, [setDevice]);

  const MenuItem = ({ children, isLast, to = '/', isExternal, ...rest }) => {
    return (
      <Text
        mb={{ base: isLast ? 0 : 5, sm: 0 }}
        mr={{ base: 0, sm: isLast ? 0 : 0, md: isLast ? 0 : 4, lg: isLast ? 0 : 7, xl: isLast ? 0 : 7 }}
        display="block"
        {...rest}
        fontSize="14px"
        fontWeight="semibold"
      >
        {isExternal ? (
          <Link href={to}>
            <a target="_blank" rel="noreferrer">
              {children}
            </a>
          </Link>
        ) : (
          <Link href={to}>{children}</Link>
        )}
      </Text>
    );
  };

  const MenuItemMobile = ({ children, isLast, to = '/', onClick, ...rest }) => {
    return (
      <Text
        mb={{ base: isLast ? 0 : '55px', sm: 0 }}
        mr={{ base: 0, sm: isLast ? 0 : 8 }}
        display="block"
        {...rest}
        fontSize="24px"
        fontWeight="800"
        onClick={
          onClick
            ? onClick
            : () => {
                setShow(false);
                Router.push(to);
              }
        }
      >
        {children}
      </Text>
    );
  };

  const onCloseMenu = () => {
    onLogout();
    setShow(false);
    userSession.signUserOut();
  };

  const onLogout = async () => {
    mutateUser(await fetchJson('/api/logout', { method: 'POST' }), false);
    Router.push('/');
  };

  const onCloseModalLogin = () => {
    authDispatch({ type: 'HIDE_MODAL' });
    // Router.push('/');
  };

  const onOpen = () => {
    authDispatch({ type: 'SHOW_MODAL' });
  };

  const openOAuth = async () => {
    const pathname = router.asPath;
    await axios.post('/api/current', { pathname });
    // console.log('pathname', pathname);
    goToOauth();
  };

  const onSearch = useMemo(
    () =>
      debounce((val) => {
        // console.log('value', val);
        setValue(val);
        setLoading(true);
        API.get(`/search?query=${val}`)
          .then((res) => {
            setCollections(res.data.data);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }, 500),
    []
  );

  const onRedirect = (uri) => {
    // console.log('uri', uri);
    router.push(uri);
    setValue('');
  };

  return (
    <Box id="header">
      {/* <Flex mb={2} id="top_header" bg="#49b6e8" p="8px" textTransform="uppercase" justifyContent="center">
        <Flex color="#fff" fontSize="14px">
          <Flex id="text" color="#fff" mr={{ base: '0', md: '0' }} alignItems="center">
            <Image src="/images/bitmap.png" alt="" mr="15px" />
            <Text color="#000" mr={3}>
              100% of{' '}
              <Link href="/collection/319">
                <a>
                  <Text as="span" fontWeight="bold" textDecoration="underline">
                    HER: NFT event
                  </Text>{' '}
                </a>
              </Link>
              sales will be donated to help those impacted by the situation in Ukraine.
            </Text>
          </Flex>
        </Flex>
      </Flex> */}
      <Box
        as="header"
        zIndex="1"
        borderBottomWidth={{ base: 0, md: '1px' }}
        borderColor={colorMode === 'dark' ? '#333' : null}
      >
        <LoginModal nft={nft} isOpen={showModal} onClose={onCloseModalLogin} />
        <Container>
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            p={['10px', '20px 10px', '20px', '20px', '16px 26px 22px 21px']}
            id="nav"
            color={color[colorMode]}
            justifyContent={{ base: 'center', md: 'center', lg: 'center', xl: 'space-between' }}
          >
            <Flex
              id="logo_search"
              alignItems="center"
              mb={{ base: '10px', md: '15px', lg: 5, xl: 0 }}
              w={{ base: '100%', md: 'auto' }}
            >
              {device === 'mobile' ? <LogoMobile /> : <Logo />}
              <Search
                value={value}
                onSearch={onSearch}
                onRedirect={onRedirect}
                loading={loading}
                collections={collections}
              />
            </Flex>

            <Box id="button_menu" display={{ base: 'block', md: 'none' }} w="100%">
              <Flex justifyContent="flex-end">
                {device === 'mobile' &&
                  (user?.isLoggedIn ? (
                    <Flex id="mobile_avatar" alignItems="center" mr="15px">
                      <Avatar
                        onClick={() => Router.push('/favorites')}
                        w="36px"
                        h="36px"
                        size="sm"
                        name={user?.username}
                        mr={{ base: 2, md: '25px' }}
                        bg="#BC3153"
                        color="#fff"
                      />
                      <Text fontSize={13} color={colorMode === 'dark' ? 'gray.300' : 'black'}>
                        {user?.username || ''}
                      </Text>
                    </Flex>
                  ) : (
                    <Button
                      mr="25px"
                      px="30px"
                      fontSize="14px"
                      fontWeight="semibold"
                      variant="black"
                      onClick={() => openOAuth()}
                    >
                      Sign Up / Sign In
                    </Button>
                  ))}
                <Flex onClick={toggleMenu} pr="7px">
                  {show ? (
                    <CloseIcon color={colorMode === 'dark' ? '#fff' : '#000'} />
                  ) : (
                    <MenuIcon color={colorMode === 'dark' ? '#fff' : '#000'} />
                  )}
                </Flex>
              </Flex>
            </Box>

            <Box
              display={{ base: show ? 'block' : 'none', md: 'block' }}
              flexBasis={{ base: '100%', md: 'auto' }}
              id="menu"
            >
              <Flex
                id="menu_content"
                align="center"
                justify={['center', 'space-between', 'flex-end', 'flex-end']}
                direction={['column', 'row', 'row', 'row']}
                pt={[2, 2, 0, 0]}
              >
                {device === 'mobile' ? (
                  <Box pt="50px" textAlign="center">
                    {/* <MenuItemMobile to="/marketplace">Marketplace </MenuItemMobile> */}
                    {user?.isLoggedIn && (
                      <Fragment>
                        <MenuItemMobile to="/favorites">My Profile </MenuItemMobile>
                      </Fragment>
                    )}
                    {!user?.isLoggedIn && (
                      <MenuItemMobile to="https://heylayer.com/discussions">
                        FAQ
                      </MenuItemMobile>
                    )}
                    {user?.isLoggedIn && <MenuItemMobile onClick={onCloseMenu}> Sign Out</MenuItemMobile>}
                  </Box>
                ) : (
                  <>
                    {/* <MenuItem to="http://blog.heylayer.com/" isExternal={true}>
                      Blog
                    </MenuItem> */}
                    <MenuItem
                      to="https://heylayer.com/discussions"
                      isExternal={true}
                    >
                      FAQ
                    </MenuItem>
                    {/* <MenuItem to="/marketplace">Marketplace </MenuItem> */}

                    {device !== 'mobile' && (
                      <AuthMenu
                        onOpen={onOpen}
                        isAuthenticated={user?.isLoggedIn}
                        access_token={user?.access_token}
                        user={user}
                        onLogout={onLogout}
                        openOAuth={openOAuth}
                      />
                    )}
                  </>
                )}
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
