import React from 'react';
import { useRouter } from 'next/router';
import { Box, Flex, useColorMode } from '@chakra-ui/react';
import { Header } from 'layouts/Header';
import { Footer } from 'layouts/Footer';
import Script from 'next/script';

const Layout = ({ children }) => {
  const router = useRouter();
  const { colorMode } = useColorMode();
  const bgColor = { light: 'white', dark: '#141414' };
  const color = { light: 'black', dark: 'white' };
  const pathname = router.pathname;
  const asPath = router.asPath;

  return (
    <Box
      id="wrapper"
      // bgColor={pathname === '/' ? '#FAFBFD' : bgColor[colorMode]}
      bgColor={bgColor[colorMode]}
      color={color[colorMode]}
      bgImage={pathname === '/' ? null : colorMode === 'dark' ? null : `url(/images/lines-bg-top.svg)`}
      // bgImage={pathname === '/' ? `url(/images/wall-white-bg.png)` : `url(/images/lines-bg-top.svg)`}
      bgSize="contain"
      bgPos="top center"
      bgRepeat="no-repeat"
      w="100%"
    >
      {pathname !==
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-VK7J6RD64L" strategy="afterInteractive" />}
      {pathname !== '/embed/nft/[id]' && (
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-VK7J6RD64L');
        `}
        </Script>
      )}

      {pathname !== '/embed/nft/[id]' ? (
        asPath === '/mintpool/4' ||
        asPath === '/mintpool/2' ||
        asPath === '/mintpool/3' ||
        asPath === '/stxldn' ? null : (
          <Header />
        )
      ) : null}
      {children}
      {pathname !== '/embed/nft/[id]' ? (
        asPath === '/mintpool/4' ||
        asPath === '/mintpool/2' ||
        asPath === '/mintpool/3' ||
        asPath === '/stxldn' ? null : (
          <Footer />
        )
      ) : null}
      {/* {pathname !== '/embed/nft/[id]' && <Footer />} */}
      {/* {pathname !== '/embed/nft/[id]' && (
        <Script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/20922456.js" />
      )} */}
      {pathname !== '/embed/nft/[id]' && (
        <Script type="text/javascript" src="https://load.fomo.com/api/v1/Vf7x_H5jVxD8ByFPVOaxCw/load.js" async />
      )}
      {/* {pathname !== '/embed/nft/[id]' && (
        <Script
          type="text/javascript"
          src="https://widget.lnswap.org/widget.js"
          id="LNSwap-Widget-Script"
          defer
          data-config="{'name': 'lnswap', 'config': {'targetElementId': 'root'}}"
        />
      )} */}
    </Box>
  );
};

export default Layout;
