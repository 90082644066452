const Input = {
  // 1. We can update the base styles
  baseStyle: {
    fontSize: '12px',
    borderColor: 'red',
  },

  // 4. We can override existing variants
  solid: (props) => ({
    bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
    fontSize: '33px',
    borderColor: 'red',
  }),
};
export default Input;
