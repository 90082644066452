/* eslint-disable react/display-name */
import { FormErrorMessage, FormLabel, FormControl, Textarea, useColorMode } from '@chakra-ui/react';

const CreateInputText =
  () =>
  ({ field, form, label, submitCount, height, hasBottom, showError = true, fontSize, onChange, ...props }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    let handleInputChange = (e) => {
      // console.log('e', e.target.value);
      let inputValue = e.target.value;
      form.setFieldValue(field.name, inputValue);
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colorMode } = useColorMode();
    return (
      <FormControl
        isInvalid={submittedError || touchedError ? true : false}
        mb={submittedError || touchedError ? '4px' : hasBottom ? hasBottom : '23px'}
      >
        {label && (
          <FormLabel mb="3px" fontSize={fontSize ? fontSize : '13px'} fontWeight="500">
            {label}
          </FormLabel>
        )}
        <Textarea
          // color="#909090"
          fontSize="14px"
          borderColor={colorMode === 'dark' ? '#333' : '#ddd'}
          borderRadius={0}
          height={height ? height : '80px'}
          {...field}
          {...props}
          onChange={handleInputChange}
          _invalid={{ borderColor: '#e53e3e' }}
          _focus={{ borderColor: '#3182ce' }}
        />
        {showError && (
          <FormErrorMessage fontSize="10px" mt={1}>
            {submittedError || touchedError ? hasError : false}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  };

export default CreateInputText;
