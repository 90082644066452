/* eslint-disable react/display-name */
import React from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { FormErrorMessage, FormLabel, FormControl, useColorMode } from '@chakra-ui/react';
import { hoverBg, hoverBgDark } from 'config/data';

const CreateSelect =
  () =>
  ({
    field,
    form,
    label,
    submitCount,
    height,
    options,
    isMulti = false,
    onSelect,
    isClearable = true,
    hasBottom,
    fontSize,
    fontWeight,
    isDisabled = false,
    isLoading = false,
    isBordered = true,
    borderColor,
    showError,
    pl,
    isInvalid,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colorMode } = useColorMode();

    const getValue = () => {
      if (options) {
        return isMulti
          ? options.filter((option) =>
              field.value ? field.value.indexOf(option.value) >= 0 : option.value === field.value
            )
          : options.find((option) => option.value === field.value);
      } else {
        return isMulti ? [] : '';
      }
    };

    const colourStyles = {
      // dropdownIndicator: (base) => ({
      //   ...base,
      //   padding: height === '30px' ? '2px 8px ' : '8px',
      // }),
      control: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: colorMode === 'dark' ? '#141414' : '#fff',
          color: colorMode === 'dark' ? '#141414' : '',
          borderColor:
            hasError || isInvalid
              ? '#e53e3e'
              : borderColor
              ? borderColor
              : colorMode === 'dark'
              ? 'rgba(255,255,255,0.04)'
              : '#E2E8F0',
          borderRadius: '5px',
          paddingLeft: pl ? pl : 0,
          minHeight: height ? height : '42px',
          fontSize: '13px',
          borderWidth: isBordered ? '1px' : 0,
          // This line disable the blue border
          boxShadow: 'none',
          '&:hover': {
            borderColor: isFocused ? '#000' : null,
          },
        };
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? '#3182ce' : colorMode === 'dark' ? '#141414' : '#fff',
          color: isSelected ? '#fff' : colorMode === 'dark' ? '#fff' : '',
          fontSize: '13px',
          padding: '5px 15px',
          ':hover': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? data.color : colorMode === 'dark' ? hoverBgDark : hoverBg),
            color: colorMode === 'dark' ? '#fff' : '',
          },
        };
      },
      singleValue: (styles) => ({ ...styles, color: colorMode === 'dark' ? '#fff' : '' }),
      indicatorSeparator: (styles) => ({ display: 'none' }),
    };

    const onChange = (option) => {
      if (isEmpty(option)) {
        form.setFieldValue(field.name, isMulti ? [] : '');
      } else {
        form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value);
      }
    };

    return (
      <FormControl
        isInvalid={isInvalid ? isInvalid : submittedError || touchedError ? true : false}
        mb={submittedError || touchedError ? '0px' : hasBottom ? hasBottom : '23px'}
      >
        {label && (
          <FormLabel mb="3px" fontSize={fontSize ? fontSize : '13px'} fontWeight={fontWeight || '600'}>
            {label}
          </FormLabel>
        )}
        <Select
          isClearable={isClearable ? isClearable : false}
          placeholder={props.placeholder || ''}
          styles={colourStyles}
          isMulti={isMulti}
          value={getValue()}
          onChange={onSelect ? onSelect : onChange}
          name={field.name}
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />

        {showError && (
          <FormErrorMessage fontSize="13px" mt={1}>
            {submittedError || touchedError ? hasError : false}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  };

export default CreateSelect;
