import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import { MoralisProvider } from 'react-moralis';
import { ToastContainer } from 'react-toastify';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import { DefaultSeo } from 'next-seo';
import { CurrencyProvider } from 'contexts/currency/currency.provider';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import TagManager from 'react-gtm-module';
import AppLayout from 'layouts/Layout';
import { useMedia } from 'utils/use-media';
import { AuthProvider } from 'contexts/auth/auth.provider';
import { AuthContextProvider } from 'contexts/0Auth';
import SEO from '../../next-seo.config';
import theme from '../theme';
import fetch from 'lib/fetchJson';
import 'public/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

export default function ExtendedApp({ Component, pageProps }) {
  const mobile = useMedia('(max-width: 580px)');
  const tablet = useMedia('(max-width: 991px)');
  const desktop = useMedia('(min-width: 992px)');
  const router = useRouter();
  const moralisAppId = 'iWSXNOem3MDRRKusXerZ2b8BACLuGbnH90bsjppP';
  const moralisServerURL = 'https://2turlesn7zky.usemoralis.com:2053/server';

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-MFLC542' });
    const handleStart = (url) => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return (
    <MoralisProvider appId={moralisAppId} serverUrl={moralisServerURL}>
      <ChakraProvider resetCSS theme={theme}>
        <CurrencyProvider>
          <ColorModeProvider
            options={{
              initialColorMode: 'light',
              useSystemColorMode: false,
              // useSystemColorMode: true,
            }}
          >
            <SWRConfig
              value={{
                fetcher: fetch,
                onError: (err) => {
                  console.error(err);
                },
              }}
            >
              <AuthContextProvider>
                <AuthProvider>
                  <AppLayout>
                    <ToastContainer />
                    <DefaultSeo {...SEO} />

                    <Component {...pageProps} deviceType={{ mobile, tablet, desktop }} />
                  </AppLayout>
                </AuthProvider>
              </AuthContextProvider>
            </SWRConfig>
          </ColorModeProvider>
        </CurrencyProvider>
      </ChakraProvider>
    </MoralisProvider>
  );
}
