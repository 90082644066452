const Button = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: 'bold', // Normally, it is "semibold"
    borderRadius: '21px',
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    xl: {
      h: '56px',
      fontSize: 'lg',
      px: '32px',
    },
  },
  // 3. We can add a new visual variant
  variants: {
    'with-shadow': {
      bg: 'red.400',
      boxShadow: '0 0 2px 2px #efdfde',
    },
    black: (props) => ({
      // bg: '#141416',
      bg: props.colorMode === 'dark' ? '#fff' : '#141416',
      color: props.colorMode === 'dark' ? '#141414' : '#fff',
      fontSize: '14px',
      _hover: {
        _disabled: { background: 'black' },
        bg: '#696969',
      },
    }),

    gray: {
      bg: '#3f4041',
      // bg: 'linear-gradient(98deg, rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.73) 97%)',
      color: 'white',
      fontSize: '14px',
      fontWeight: '500',
      _hover: {
        bg: '#696969',
      },
    },
    // // 4. We can override existing variants
    // solid: (props) => ({
    //   bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
    // }),
  },
};
export default Button;
