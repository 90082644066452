import CreateInput from './Input';
import CreateInputText from './InputTextarea';
import CreateInputCard from './InputCard';
import CreateInputCleave from './InputCleave';
import CreateSelect from './InputSelect';
import CreateSelectCountry from './InputSelectCountry';
// import CreateSelectValue from './InputSelectValue';
// import CreateSelectSearch from './InputSelectSearch';
import CreateSelectCreatable from './InputSelectCreate';

export const Input = CreateInput();
export const InputText = CreateInputText();
export const InputCard = CreateInputCard();
export const InputCleave = CreateInputCleave();
export const Select = CreateSelect();
export const SelectCountry = CreateSelectCountry();
// export const SelectValue = CreateSelectValue();
// export const SelectSearch = CreateSelectSearch();
export const SelectCreatable = CreateSelectCreatable();
