import axios from 'axios';
// import Cookies from 'js-cookie';

export const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: 'application/json',
    // Authorization: `${Cookies.get('access_token')}` || undefined,
  },
});

API.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      // Authorization: `${Cookies.get('access_token')}` || undefined,
      Accept: 'application/json',
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
